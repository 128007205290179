@import url("https://fonts.googleapis.com/css2?family=PT+Sans:wght@700&display=swap");

.sans {
  /*   font-family: "PT Sans", sans-serif;
  font-weight: 700; */

  font-family: "PT Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
}

.unstyled {
  -webkit-appearance: none;
}
.unstyled::-webkit-inner-spin-button,
.unstyled::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

select.decorated option:hover {
  box-shadow: 0 0 10px 100px #cc0201 inset;
}

option:hover {
  background-color: #cc0201;
}
