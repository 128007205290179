.div { 
    position: absolute; 
    top: 50%; 
    left: 50%; 
    transform: translate(-45%, -0%) rotate(270deg); 
    height: 300px; 
    width: 620px; 
    background-color:#9ae6b3;
      border-radius: 300px 300px 0 0; }

  .circle-wrapper {
    position: relative;
    width: 100%;
    height: 100%; 
  }

  .counterRotate {
    transform:  translate(95%, 8%)  rotate(-270deg); 
  }