.icon {
  z-index: 2;
  width: 50px;
  height: 50px;
}

.icon_left {
  position: fixed;
  z-index: 2;
  top: 0px;
  left: 0px;
  border-radius: 5px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 0px;
  border-color: black;
  border-width: 2px;
  border-left-width: 0px;
  border-top-width: 0px;
  width: 50px;
  height: 50px;
}
